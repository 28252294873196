import { gql } from "@apollo/client/core"


export const ADMIN_CHALLENGE_LIST = gql`
query MyQuery($challenge_id: String = "") {
  participations:participation_aggregate(where: {challenge_id: {_eq: $challenge_id}, _and: {_not: {_and: {team_id: {_is_null: true}, participation_type: {_eq: "team"}}}}}) {
    aggregate {
      count
    }
  }
  solo_participations:participation_aggregate(where: {challenge_id: {_eq: $challenge_id}, _and:  {_and: {team_id: {_is_null: true}, participation_type: {_eq: "solo"}}}}) {
    aggregate {
      count
    }
  }
  looking_for_team:participation_aggregate(where: {challenge_id: {_eq: $challenge_id}, _and: {_and: {team_id: {_is_null: true}, participation_type: {_eq: "team"}}}}) {
    aggregate {
      count
    }
  }
  submission_aggregate(where: {participation: {challenge_id: {_eq: $challenge_id}}, _and: {_or: {participation_id: {_is_null: false}}, team_id: {_is_null: false}}}) {
    aggregate {
      count
    }
  }
  unique_team_submissions:submission_aggregate(
    where: {
      participation: {
        challenge_id: {_eq: $challenge_id}
      },
      _and: {
        team_id: {_is_null: false}}
    }, distinct_on: [team_id]
  ) {
    aggregate {
      count
    }
  }
  unique_solo_submissions:submission_aggregate(
    where: {
      participation: {
        challenge_id: {_eq: $challenge_id}
      },
      _and: {
        team_id: {_is_null: true}}
    }, distinct_on: [participation_id]
  ) {
    aggregate {
      count

    }
  }
  team_aggregate(where: {challenge_id: {_eq: $challenge_id}}) {
    aggregate {
      count
    }
  }
  request_aggregate(where: {team: {challenge_id: {_eq: $challenge_id}}}) {
    aggregate {
      count
    }
  }
}
`


export const AMDIN_PARTICIPATION_LIST = gql`
query MyQuery($challenge_ids: [String!] = "") {
  participation(where: {challenge_id: {_in: $challenge_ids}, _and: {_not: {_and: {participation_type: {_eq: "team"}, team_id: {_is_null: true}}}}}) {
    id
    challenge_id
    participation_type
    created_at
    profile {
      id
      display_name
      photo_url
      email
      created_at
    }
    submissions(order_by: {updated_at: desc}, limit: 1) {
      id
      created_at
      files
    }
    tag_participations {
      tag_id
      tag {
        hex_code
        name
      }
    }
    team {
      id
      name
      data
      created_at
      team_profiles {
        profile {
          id
          display_name
          photo_url
        }
      }
      team_profiles_aggregate {
        aggregate {
          count
        }
      }
      requests(where: {status: {_eq: "pending"}}) {
        id
      }
      submissions(order_by: {updated_at: desc}, limit: 1) {
        id
        created_at
        files
        participation {
          profile {
            display_name
          }
        }
      }
    }
  }
}
`

export const LIST_REQUESTS_BY_CHALLENGE = gql`
query AdminRequestList($challenge_ids: [String!]) {
  request(where: {team: {challenge_id: {_in: $challenge_ids}}}) {
    created_at
    updated_at
    id
    profile {
      id
      display_name
      email
      photo_url
    }
    team {
      id
      challenge_id
      name
      data
      requests(where: {status: {_eq: "pending"}}) {
        id
      }
      team_profiles {
        profile {
          id
          display_name
          photo_url
        }
      }
    }
    status
    messages {
      id
      content
      profile {
        email
        display_name
        id
        photo_url
      }
    }
  }
}

`

export const LIST_SUBMISSIONS_BY_CHALLENGE = gql`
fragment submissionByChallenge on submission {
  id
  files
  participants
  created_at
  updated_at
  participation {
    participation_type
    profile {
      id
      display_name
      email
    }
  }
  team {
    id
    name
    team_profiles {
      profile {
        id
        id
        display_name
        photo_url
      }
    }
  }
}

query SubmissionByChallenge($challenge_ids: [String!]) {
  team_submissions:submission(
    order_by:[ {team_id: desc}, {created_at: desc}],
    distinct_on: [team_id],
    where: {
      participation: {challenge_id: {_in: $challenge_ids}},
      _and: {team_id:{ _is_null: false}}
    }
  ) {
  ... submissionByChallenge
  }
  solo_submissions:submission(
  order_by:[{participation_id: desc} {created_at: desc}],
      distinct_on: [participation_id],
  where: {
    participation: {challenge_id: {_in: $challenge_ids}},
    _and: {team_id:{_is_null: true}}
  }
  ) {
  ... submissionByChallenge
  }

}
`


export const QUERY_USER_LOOKING_FOR_TEAM = gql`
query UserLookingForTeam($challenge_ids: [String!] = "") {
  profile(where: {participations: {challenge_id: {_in: $challenge_ids}, _and: {_and: {team_id: {_is_null: true}, participation_type: {_eq: "team"}}}}}) {
    id
    display_name
    photo_url
    email
    requests(where: {team: {challenge_id: {_in: $challenge_ids}}}) {
      status
      team {
        name
        team_profiles {
          profile {
            id
            display_name
            photo_url
          }
        }
      }
    }
  }
}
`

export const ADMIN_QUERY_REQUESTS = gql`
query GetProfileRequests($challenge_id: Int!) {
  profile_by_pk(id: $challenge_id) {
    display_name
    photo_url
    requests {
      created_at
      updated_at
      status
      team {
        name
        challenge_id
        team_profiles {
          profile {
            id
            display_name
            photo_url
          }
        }
      }
    }
  }
}
`

const TEAM_INFO_FRAGMENT = gql`
fragment teamInfoFragment on team {
  data
  created_at
  name
  team_profiles {
    profile {
      id
      display_name
      photo_url
      participations(where: {id: {_eq: $participation_id}}) {
        created_at
      }
    }
  }
  submissions(order_by: {created_at: desc}, limit: 1) {
    created_at
    files
    id
  }
}
`

export const GET_TEAM_INFO_BY_TEAM_ID = gql`
query GetTeamInfoByTeamId($id: Int!) {
  team_by_pk(id: $id){
    data
    created_at
    name
    team_profiles {
      profile {
        id
        display_name
        photo_url
      }
    }
  }
}

`



export const GET_PARTICIPATION_BY_ID = gql`
${TEAM_INFO_FRAGMENT}
query AdminParticipation($participation_id: Int!) {
  participation_by_pk(id: $participation_id) {
    created_at
    participation_type
    profile {
      id
      display_name
      email
      data
      created_at
    }
    submissions(order_by: {updated_at: desc}, limit: 1) {
      id
      created_at
      files
    }
    team {
      ... teamInfoFragment
    }
  }
}
`



export const GET_SUBMISSION_BY_ID = gql`
query SubmissionById($id: Int!) {
  submission_by_pk(id: $id) {
    files
    created_at
    updated_at
    team {
      name
    }
    participation {
      profile {
        id
        display_name
      }
    }
  }
}
`


export const GET_PROFILE_COUNT = gql`
query ProfileAggregate {
  profile_aggregate {
    aggregate {
      count
    }
  }
}
`


export const LATEST_USERS = gql`
query LatestUsers {
  profile(limit: 10, order_by: {created_at: desc}) {
    display_name
    data
    created_at
    email
    participations {
      challenge_id
    }
  }
}
`
