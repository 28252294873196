import { gql } from "@apollo/client/core";


export const SET_SEEN_BY_TYPE = gql`
mutation MyMutation($type: String, $profileId: Int) {
  update_notification(where: {type: {_eq: $type}, profile_id: {_eq: $profileId}}, _set: {is_seen: true}) {
    returning {
      id
    }
  }
}
`;

export const SUBSCRIPTION_NOTIFICATION = gql`
subscription MySubscription($profileId: Int) {
  notification(where: {profile_id: {_eq: $profileId}, is_seen: {_eq: false}}) {
    type
    profile_id
    is_seen
    id
    data
  }
}
`;

export const INSERT_NOTIFICATION = gql`
mutation MyMutation($profileId: Int, $type: String) {
  insert_notification_one(object: {profile_id: $profileId, type: $type}) {
    id
  }
}
`;

export const INSERT_MULTIPLE_NOTIFICATION = gql`
mutation MyMutation($objects: [notification_insert_input!]!) {
  insert_notification(objects: $objects) {
    affected_rows
  }
}
`;
