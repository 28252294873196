import { gql } from "@apollo/client/core";




export const NEWSFEED_OVERVIEW = gql`
{
    allNewsfeeds{
      edges{
        node{
          _meta{
          uid }
          newsfeed
          slacklinkglobal
        }
      }
    }
    }
`;
