export class Challenge {
  skills_input_on_create_team: 'true' | 'false';
  uni_required: 'true' | 'false';
  qualification_ended: 'true' | 'false';
  is_solo_participation_disallowed: boolean;
  is_completed: boolean;
  eventid: string;
  usecase: any;
  status: string;
  teaser: any[];
  extra_teasers: any;
  title: any[];
  brief: any[];
  qualification: Date;
  current_deadline: Date;
  challenge_card_text_color: string;
  challenge_card_background_color: string;
  challenge_teaser_text_color: string;
  challenge_teaser_background_color: string;
  final: Date;
  logo: ChallengeImageContent | null;
  cover: ChallengeImageContent | null;
  card_cover: ChallengeImageContent | null;
  criteria: any[];
  eligible_region: 'Germany only' | 'DACH Region only' | 'Europe only' | 'International';
  criteria_short: any[];
  company: any[];
  faq: any[];
  about: any[];
  prize: any[];
  winners: any[];
  timeline: any[];
  next_step_1: any[];
  next_step_2: any[];
  next_step_3: any[];
  terms: any[];
  confidential: any | null;
  cta: any;
  create_entity: any;
  create_entity_title: any;
  join_entity: any;
  entity_name: any;
  entity_name_helper: any;
  entity_description_helper: any;
  announcement: any[];
  _meta: any;
  tabs_view_mode: 'joined_page' | 'separate_pages';
  extra_tabs: any[];
  slacklink?: any[];
  project_phases: any;
  important_details_tab_title: string;
  //SEO
  page_title: string;
  page_description: string;
  card_image: { url: string }


  get uid(): string {
    return this._meta.uid;
  }

  get id(): string {
    return this._meta.id;
  }

  get lastPublicationDate(): Date {
    return this._meta.lastPublicationDate;
  }

  get logoURL(): string {
    return this.logo?.url;
  }

  get cardCoverURL(): string {
    return this.card_cover?.url || this.cover?.url;
  }
  get coverURL(): string {
    return this.cover?.url || this.cover?.url;
  }

  get hasSlackLink(): boolean {
    return !!this.slacklink
  }
}





export class ChallengeImageContent {
  dimension: {
    width: number;
    height: number;
  };
  alt: any;
  copyright: any;
  url: string;
}
