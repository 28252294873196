export const allSkills: string[] = [
  'Online Marketing',
  'BWL',
  'Business',
  'Consulting',
  'Finanzen',
  'Finance',
  'E - Commerce',
  'Research',
  'Sales / Retail',
  'Customer Relationship Management',
  'Change Management',
  'Entrepreneurship',
  'Forecasting',
  'Key Performance Indicators',
  'Knowledge Management',
  'Negotiation',
  'Network Communication',
  'Performance Management',
  'Pricing Strategy',
  'Quality Management',
  'Business Development',
  'Accounting',
  'Controlling',
  'Produktentwicklung',
  'Product Development',
  'Research',
  'Strategie',
  'Strategy',
  'VWL',
  'Economics',
  'Scrum-Methodology',
  'Project Planning',
  'Product Lifestyle Management',
  'Strategic Planning',
  'Budgeting',
  'Time Management',
  'Überwachung & Steuerung',
  'Supervision & Controlling',
  'Methoden Kenntnisse',
  'Method Knowledge',
  'Data Analytics',
  'HTML',
  'Java Script',
  'SQL',
  'Python',
  'Java',
  'Linux',
  'IOS',
  'Windows',
  'Testing',
  'Web Applications',
  'Web Development',
  'CSS',
  'jQuery',
  'Wordpress',
  'SAP',
  'Künstliche Intelligenz',
  'Artificial Intelligence',
  'Angular',
  'ReactJs',
  'Design',
  'User Interface Design',
  'Grafik Design',
  'Graphic Design',
  'Video Production',
  'Video Editing',
  'Web Design',
  'Photo Editing',
  'Excel',
  'Powerpoint',
  'Photoshop',
  'Illustrator',
  'SPSS',
  'Word',
  'Stata',
  'Mechanical Engineering',
  'Social Media',
  'Legal/Law',
  'Branding',
  'Food Marketing',
  'Regulation',
  'User Experience',
  'Fotographie',
  'Photography',
];
