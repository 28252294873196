import { gql } from "@apollo/client/core";
import { challengeCardFragment } from "./challenges.query";

export const GET_PROGRAMS_OVERVIEW = gql`
query MyQuery($lang: String) {
  allPrograms(lang: $lang){
		edges{
      node {
        _meta {
          uid
          id
        }
        title
        is_listed
      }
    }
  }
}
`


export const GET_PROGRAM_BY_ID = gql`
${challengeCardFragment}
query MyQuery($uid: String, $lang: String) {
  allPrograms(uid: $uid, lang: $lang) {
    edges {
      node {
        _meta {
          uid
          id
        }
        title
        page_title
        page_description
        card_image {
          ... on _ExternalLink {
            url
          }
          ... on _ImageLink {
            url
          }
        }
        body {
          __typename
          ... on ProgramBodyKey_figures {
            type
            label
            fields{
              figure
              figure_title
            }
          }
          ... on ProgramBodyTeaser {
            type
						label
            fields {
              button_link {
                _linkType
                __typename
                ... on _ExternalLink {
                  url
                }
                ... on _FileLink {
                  url
                }
              }
              button_label
              button_color
            }
            primary {
              text
              background
              teaser_text_background_color
            }
          }
          ... on ProgramBodyCall_to_action {
            fields {
              button_link {
                _linkType
                __typename
                ... on _ExternalLink {
                  url
                }
                ... on _FileLink {
                  url
                }
              }
              button_label
              color
            }
            primary {
              has_horizontal_padding
              visual_position
              title
              paragraph
              visual
              text_alignment
            }
          }
          ... on ProgramBodyCustomer_logos {
            label
            fields {
              logo
              link {
                _linkType
                ... on _ExternalLink{
                  url
                }
              }
            }
            primary {
              eyebrow_headline
              eyebrow_description
            }
          }
          ... on ProgramBodyChallenge {
            primary {
              challenges_title
              challenges_subtitle
              text_alignment
            }
            fields {
              challenge {
                ... on Challenge {
                  ... challengeCardFragment
                }
              }
            }
          }

          ... on ProgramBodyFaq_section {
						type
            label
            primary {
              eyebrow_headline
              title
              description
              optional_image
            }
            fields {
              title
              text
            }
          }

          ... on ProgramBodyContent {
            type
            label
            fields {
              content
            }
          }
        }
      }
    }
  }
}
`
