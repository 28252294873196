import { gql } from "@apollo/client/core";

export const RESPONSE_TO_REQUEST = gql`
mutation MyMutation($status: String!, $requestId: Int!) {
  reponseToRequest(args: {requestId: $requestId, status: $status}) {
    requestId
  }
}
`;

export const PARTICIPATE = gql`
mutation MyMutation($challengeId: String = "", $participationType: String = "") {
  participate(args: {challengeId: $challengeId, participationType: $participationType}) {
    participationId
  }
}
`
export const CREATE_TEAM_AND_PARTICIPATE = gql`
mutation MyMutation($teamInput: jsonb = "", $challengeId: String = "") {
  createTeamAndParticipate(args: {teamInput: $teamInput, challengeId: $challengeId}) {
    teamId
  }
}
`

export const LEAVE_CHALLENGE = gql`
mutation MyMutation($challengeId: String = "") {
  leaveChallenge(challengeId: $challengeId) {
    affectedRows
  }
}
`


export const DELETE_ACCOUNT = gql`
mutation DeleteAccountAction {
  deleteAccount {
    affectedRows
  }
}
`

export const SEND_VERIFICATION_EMAIL = gql`
mutation MyMutation($userUid: String = "") {
  sendVerificationEmail(userUid: $userUid) {
    message
  }
}
`;
