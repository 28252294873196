import { gql } from "@apollo/client/core";



export const INSERT_FILE = gql`
mutation MyMutation($download_url: String, $team_id: Int, $path: String = "", $name: String = "", $participation_id: Int!) {
  insert_file_one(object: {download_url: $download_url, team_id: $team_id, path: $path, name: $name, participation_id: $participation_id}) {
    id
  }
}
`;

export const GET_FILES_BY_TEAM_ID = gql`
subscription MySubscription($team_id: Int) {
  file(where: {team_id: {_eq: $team_id}}) {
    created_at
    download_url
    id
    name
    path
    team_id
  }
}
`

export const GET_FILES_BY_PARTICIPATION_ID = gql`
subscription MyQuery($participation_id: Int!) {
  file(where: {participation: {id: {_eq: $participation_id}}}) {
    created_at
    download_url
    id
    name
    participation_id
    team_id
  }
}
`


export const DELETE_FILE_BY_UUID = gql`
mutation MyMutation($uuid: uuid = "") {
  delete_file_by_pk(id: $uuid) {
    id
  }
}
`
