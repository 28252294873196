import { gql } from "@apollo/client/core";


export const GET_PARTICIPATION_BY_PROFILE_AND_CHALLENGE = gql`
query MyQuery($challenge_id: String, $profile_id: Int) {
  participation(where: {challenge_id: {_eq: $challenge_id}, profile_id: {_eq: $profile_id}}) {
    team {
      team_profiles_aggregate {
        aggregate {
          count
        }
      }
      id
    }
    profile {
      id
      user_uid
    }
  }
}
`

export const GET_USER_PARTICIPATIONS = gql`
query GetParticipationsQuery($profile_id: Int) {
  participation(where: {profile_id: {_eq: $profile_id}}) {
    challenge_id
    participation_type
    team_id
    profile_id
    team {
      name
    }
  }
}
`

export const GET_USER_PARTICIPATION_FOR_DASHBOARD = gql`
query GetParticipationsExtendedQuery($profile_id: Int) {
  participation(where: {profile_id: {_eq: $profile_id}}) {
    challenge_id
    participation_type
    team_id
    profile_id
    team {
      name
      team_profiles {
        profile {
          id
          display_name
          photo_url
        }
      }
      files_aggregate {
        aggregate {
          count
        }
      }
      requests_aggregate {
        aggregate {
          count
        }
      }
    }
    submissions_aggregate {
        aggregate {
          count
        }
    }
  }
}

`


export const GET_USER_PARTICIPATION_WITH_CHALLENGE_ID = gql`
subscription GetParticipationsWithChallengeIdQuery($profile_id: Int, $challenge_id: String = "") {
  participation(where: {profile_id: {_eq: $profile_id}, _and: {challenge_id: {_eq: $challenge_id}}}) {
    challenge_id
    participation_type
    team_id
    profile_id
    team {
      name
    }
    id
  }
}
`

export const INSERT_PARTICIPATION_AND_TEAM = gql`
mutation MyMutation($challenge_id: String = "", $participation_type: String = "", $team_name: String = "", $data: jsonb = "", $migration_id: String = "", $profile_id: Int = 10) {
  insert_participation_one(object: {team: {data: {challenge_id: $challenge_id, name: $team_name, data: $data, migration_id: $migration_id}}, challenge_id: $challenge_id, participation_type: $participation_type, profile_id: $profile_id}) {
    id
    team {
      id
    }
    profile {
      id
      user_uid
    }
  }
}
`

export const INSERT_PARTICIPATION = gql`
mutation MyMutation($challenge_id: String = "", $participation_type: String = "", $profile_id: Int, $team_id: Int = null) {
  insert_participation_one(object: {challenge_id: $challenge_id, participation_type: $participation_type, profile_id: $profile_id, team_id: $team_id}) {
    participation_type
    challenge_id
    id
    profile {
      id
      user_uid
    }
  }
}
`

export const DELETE_PARTICIPATION = gql`
mutation MyMutation($challenge_id: String, $profile_id: Int) {
  delete_participation(where: {challenge_id: {_eq: $challenge_id}, profile_id: {_eq: $profile_id}}) {
    affected_rows
  }
}
`


export const GET_PARTICIPANTS_BY_PARTICIPATION_ID = gql`
query GetParticipantsByParticipationId($participation_id: Int!) {
  participation_by_pk(id: $participation_id) {
    challenge_id
    profile {
      id
      display_name
      email
    }
    team {
      name
      team_profiles {
        profile {
          id
          display_name
          email
        }
      }
    }
  }
}
`

export const GET_REQUESTS_BY_TEAM_ID = gql`
query GetRequestsByTeamId($id: Int!) {
  team_by_pk(id: $id) {
      requests(where: {status: {_eq: "pending"}}) {
        profile {
          id
          email
          display_name
          photo_url
          created_at
        }
      }
  }
}
`


export const GET_PARTICIPATIONS_BY_TIME_RANGE = gql`
query GetParticipationsByTimeRange($start: timestamptz!, $end: timestamptz!) {
  participation(where: {created_at: {_gte: $start, _lte: $end}}) {
    challenge_id
    created_at
  }
}
`
