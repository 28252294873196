var MissingFieldError = function () {
  function MissingFieldError(message, path, query, variables) {
    this.message = message;
    this.path = path;
    this.query = query;
    this.variables = variables;
  }
  return MissingFieldError;
}();
export { MissingFieldError };
