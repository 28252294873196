import { gql } from "@apollo/client/core";


export const challengeCardFragment = `
fragment challengeCardFragment on Challenge {
  _meta {
    id
    uid
    lastPublicationDate
  }
  uni_required
  eligible_region
  qualification_ended
  is_solo_participation_disallowed
  is_completed
  status
  teaser
  winners
  title
  qualification
  final
  next_step_1
  next_step_2
  project_phases {
    name
    deadline
    state
  }
  logo
  cover
  card_cover
  prize
  company
}
`


export const GET_CHALLENGE_BY_ID = gql`
query MyQuery($uid: String, $lang: String, $uid_in: [String!]) {
  allChallenges(uid: $uid, lang: $lang, uid_in: $uid_in) {
    edges {
      node {
        _meta {
          uid
        }
        buttons {
          challenge_overview_button_title
          challenge_overview_button_link {
            ... on _ExternalLink {
              url
              target
            }
            ... on _ImageLink {
              url
            }
            ... on _FileLink {
              url
            }
          }
        }
        skills_input_on_create_team
        uni_required
        eligible_region
        qualification_ended
        is_solo_participation_disallowed
        is_completed
        status
        teaser
        extra_teasers {
          teaser_header
          teaser_content
          hide_teaser_entry_after_challenge_is_completed
        }
        title
        brief
        faq {
          header
          content
          }
        qualification
        current_deadline
        challenge_card_text_color
        challenge_card_background_color
        challenge_teaser_text_color
        challenge_teaser_background_color
        final
        teammax
        teammin
        logo
        cover
        company
        card_cover
        criteria
        criteria_short
        about
        prize
        winners
        timeline
        next_step_1
        next_step_2
        terms
        confidential
        cta
        create_entity
        create_entity_title
        join_entity
        entity_name
        entity_name_helper
        entity_description_helper
        how_does_it_work_button_text
        announcement
        tabs_view_mode
        extra_tabs {
          tab_name
          tab_id
          tab_content
        }
        project_phases {
          name
          deadline
          state
        }
        slacklink
        _linkType
        card_cover
        important_details_tab_title
        page_title
        page_description
        card_image {
          ... on _ExternalLink {
            url
          }
          ... on _ImageLink {
            url
          }
        }
        body {
          __typename
          ... on ChallengeBodyInteractive_section {
            type
            fields {
              interactive_section_icon
              interactive_section_title
              interactive_section_content
            }
          }
          ... on ChallengeBodyCard_list {
            type
            label
            fields {
              card_entry_image
              card_entry_title
              card_entry_content
            }
          }
          ... on ChallengeBodyContent {
            type
            label
            fields {
              content
            }
          }
        }
      }
    }
  }
}
`;


export const CHALLENGES_OVERVIEW = gql`
${challengeCardFragment}
query ($lang: String, $is_completed: Boolean, $is_listed: Boolean) {
  allChallenges (lang: $lang,
    where :{
			is_completed: $is_completed,
      is_listed: $is_listed
    },
    first: 140,
    sortBy:final_DESC
  ){
    edges {
      node {
        ... challengeCardFragment
      }
    }
  }
}
`;


export const ALL_CHALLENGES_OVERVIEW = gql`
${challengeCardFragment}
query ($lang: String, $is_listed: Boolean) {
  allChallenges (lang: $lang,
    where :{
      is_listed: $is_listed
    },
    first: 140,
    sortBy:final_DESC
  ){
    edges {
      node {
        ... challengeCardFragment
      }
    }
  }
}
`;




