import { gql } from "@apollo/client/core";


export const INSERT_SUBMISSION_TEMPLATE = gql`
mutation InsertSubmissionTemplate($data: jsonb = "", $name: String = "") {
  insert_submission_template_one(object: {data: $data, name: $name, is_activated: false}) {
    data
    created_at
    challenge_id
    id
    is_activated
    name
  }
}
`;


export const GET_ALL_SUBMISSION_TEMPLATES = gql`
query GetSubmissionTemplate {
  submission_template {
    challenge_id
    created_at
    data
    id
    is_activated
    name
    updated_at
  }
}
`;

export const GET_SUBMISSION_TEMPLATE_BY_ID = gql`
query GetSubmissionQuery($challenge_id: String = "") {
  submission_template(where: {challenge_id: {_eq: $challenge_id}}) {
    challenge_id
    created_at
    data
    id
    is_activated
    name
    updated_at
  }
}
`

export const GET_SUBMISSION_TEMPLATE_BY_CHALLENGE_ID = gql`
query GetSubmissionTemplateById($id: Int!) {
  submission_template_by_pk(id: $id) {
    challenge_id
    created_at
    data
    id
    is_activated
    name
    updated_at
  }
}
`

export const UPDATE_TEMPLATE_CHALLENGE_ID = gql`
mutation UpdateTemplateChallengeId($template_id: Int!, $challenge_id: String = "") {
  update_submission_template_by_pk(pk_columns: {id: $template_id}, _set: {challenge_id: $challenge_id}) {
    id
  }
}
`
