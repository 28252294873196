// https://dev.to/jdgamble555/forcing-angular-to-wait-on-your-async-function-2ck1


import { firstValueFrom, isObservable, Observable } from 'rxjs';

declare const Zone: any;

export async function waitFor<T>(prom: Promise<T> | Observable<T>): Promise<T> {
  if (isObservable(prom)) {
    prom = firstValueFrom(prom);
  }
  const macroTask = Zone.current
    .scheduleMacroTask(
      `WAITFOR-${Math.random()}`,
      () => { },
      {},
      () => { }
    );
  return prom.then((p: T) => {
    macroTask.invoke();
    return p;
  });
}
