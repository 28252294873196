import { gql } from "@apollo/client/core";

export const GET_SUBMISSIONS_BY_PARTICIPATION_ID = gql`
subscription MySubscription($participation_id: Int) {
  submission(where: {participation_id: {_eq: $participation_id}}) {
    participation {
      team {
        id
      }
    }
    files
    id
    created_at
    updated_at
  }
}
`;


export const GET_SUBMISSIONS_BY_TEAM_ID = gql`
subscription MySubscription($team_id: Int!) {
  submission(where: { team_id: {_eq: $team_id}}) {
    files
    id
    participation {
      team {
        id
      }
    }
    created_at
    updated_at
  }
}
`

export const INSERT_SUBMISSION = gql`
mutation MyMutation($files: jsonb = "", $participation_id: Int!, $team_id: Int!, $participants: jsonb = "") {
  insert_submission_one(object: {files: $files, participation_id: $participation_id, team_id: $team_id, participants: $participants}) {
    files
    created_at
    updated_at
    team_id
  }
}
`;
