import { UniversityModel } from '../models/university.model';

export const universities: UniversityModel[] = [
  {
    uid: 'goethe',
    name: 'Goethe',
    studentCount: '45.000',
    city: 'Frankfurt am Main',
    logoURL: 'https://upload.wikimedia.org/wikipedia/de/thumb/f/f0/Goethe-Logo.svg/1200px-Goethe-Logo.svg.png'
  },
  {
    uid: 'uni_mannheim',
    name: 'Universität Mannheim',
    studentCount: 'über 12.000',
    city: 'Mannheim',
    logoURL: 'https://upload.wikimedia.org/wikipedia/commons/0/04/University_of_Mannheim.svg',
  },
  {
    uid: 'uni_mainz',
    name: 'Johannes Gutenberg Universität Mainz',
    studentCount: '35.000',
    city: 'Mainz',
    logoURL: 'https://upload.wikimedia.org/wikipedia/commons/8/8a/Johannes_Gutenberg-Universit%C3%A4t_Mainz_logo.svg'
  },
  {
    uid: 'uni_marburg',
    name: 'Philipps-Universität Marburg',
    studentCount: '26.000',
    city: 'Marburg',
    logoURL: 'https://upload.wikimedia.org/wikipedia/commons/9/9c/Uni_Marburg_Logo.svg'
  },
  {
    uid: 'uni_kassel',
    name: 'Universität Kassel',
    studentCount: '24.000',
    city: 'Kassel',
    logoURL: 'https://upload.wikimedia.org/wikipedia/commons/9/99/Logo_Uni-Kassel.svg'
  },
  {
    uid: 'ebs',
    name: 'EBS Universität',
    studentCount: '2000',
    city: 'Hattenheim',
    logoURL: 'https://upload.wikimedia.org/wikipedia/commons/c/ce/EBS_Universit%C3%A4t_f%C3%BCr_Wirtschaft_und_Recht_Logo.svg'
  },
  {
    uid: 'whu',
    name: 'Otto Beisheim School of Managemen (WHU) ',
    studentCount: '1.300',
    city: 'Valendar',
    logoURL: 'https://upload.wikimedia.org/wikipedia/de/thumb/7/79/WHU-Logo..svg/1200px-WHU-Logo..svg.png'
  },
  {
    uid: 'fh_accadis',
    name: 'accadis Hochschule',
    studentCount: '550',
    city: 'Bad Homburg',
    logoURL: 'https://upload.wikimedia.org/wikipedia/commons/9/99/Accadis_Hochschule_Bad_Homburg_logo.svg'
  },
  {
    uid: 'tu-darmstadt',
    name: 'TU Darmstadt',
    studentCount: '26.000',
    city: 'Darmstadt',
    // https://de.wikipedia.org/wiki/Datei:TU_Darmstadt_Logo.svg
    logoURL: 'https://upload.wikimedia.org/wikipedia/de/thumb/2/24/TU_Darmstadt_Logo.svg/1200px-TU_Darmstadt_Logo.svg.png'
  },
  {
    uid: 'hfg_offenbach',
    name: 'HfG Offenbach',
    studentCount: '700',
    city: 'Offenbach am Main',
    logoURL: 'https://www.hfg-offenbach.de/system/attachments/files/54b7e33268666778df2b0000/c8/hfg_pos_01_02_04.jpg?1421337387'
  },
  {
    uid: 'fh_darmstadt',
    name: 'Hochschule Darmstadt',
    studentCount: '15.000',
    city: 'Darmstadt',
    logoURL: 'https://de.wikipedia.org/wiki/Hochschule_Darmstadt#/media/File:Hda_logo.svg',
  },
  {
    uid: 'kit_karlsruhe',
    name: 'Karlsruhe Institut für Technologie',
    studentCount: '26.000',
    city: 'Karlsruhe',
    logoURL: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3a/Logo_KIT.svg/1000px-Logo_KIT.svg.png'
  },
  {
    uid: 'fsf',
    name: 'Frankfurt School of Finance & Management',
    studentCount: '1.500',
    city: 'Frankfurt am Main',
    // logoURL: 'https://pbs.twimg.com/profile_images/456748461535141888/RA1Wux12_400x400.jpeg'
  },
  {
    uid: 'uni_giessen',
    name: 'Justus-Liebig-Universität Gießen',
    studentCount: '26.000',
    city: 'Giessen',
  },
  {
    uid: 'fh_mittelhessen',
    name: 'Technische Hochschule Mittelhessen (THM)',
    studentCount: 'über 17.000',
    city: 'Giessen',
  },
  {
    uid: 'tu_aachen',
    name: 'Rheinisch-Westfälische Technische Hochschule Aachen (RWTH Aachen)',
    studentCount: 'über 45.000',
    city: 'Aachen',
  },
  {
    uid: 'fh_mainz',
    name: 'Hochschule Mainz',
    studentCount: 'über 5000 ',
    city: 'Mainz'
  },
  {
    uid: 'ism',
    name: 'International School of Management (ISM)',
    city: 'Frankfurt am Main',
  },
  {
    uid: 'fh_rheinmain',
    name: 'Hochschule RheinMain',
    city: 'Wiesbaden',
    studentCount: 'über 11.000',
  },
  {
    uid: 'fh_frankfurt',
    name: 'Frankfurt UAS',
    city: 'Frankfurt am Main',
    studentCount: '15.000',
  },
  {
    uid: 'uni_hohenheim',
    name: 'Universität Hohenheim',
    city: 'Stuttgart',
    studentCount: '9.700',
  }

];

