import { gql } from "@apollo/client/core";



export const CREATE_TEAM = gql`
mutation CreateTeam($challenge_id: String, $name: String, $data: jsonb = "", $migration_id: String = "") {
  insert_team_one(object: {challenge_id: $challenge_id, name: $name, data: $data, migration_id: $migration_id}) {
    id
    migration_id
  }
}
`;

export const UPDATE_TEAM = gql`
mutation MyMutation($id: Int, $name: String, $data: jsonb = "") {
  update_team(where: {id: {_eq: $id}}, _set: {name: $name, data: $data}) {
    returning {
      id
      name
      data
    }
  }
}`;


export const GET_TEAM_BY_CHALLENGE_ID = gql`
query MyQuery($challenge_id: String) {
  team(where: {challenge_id: {_eq: $challenge_id}}) {
    id
    name
    data
    is_closed
    team_profiles {
      profile {
        display_name
        photo_url
        id
      }
    }
  }
}
`

export const GET_TEAM = gql`
subscription MyQuery($profile_id: Int!, $challenge_id: String) {
  team(where: {challenge_id: {_eq: $challenge_id}, team_profiles: {profile_id: {_eq: $profile_id}}}) {
    id
    name
    data
    is_closed
    team_profiles {
      profile {
        data
        type
        display_name
        photo_url
        id
      }
    }
  }
}`;

export const GET_TEAM_BY_ID_COMPACT = gql`
query GET_TEAM_BY_ID_COMPACT ($id:Int){
  team(where: {id: {_eq: $id}}) {
    name
    challenge_id
  }
}
`


export const GET_TEAMS_BY_CHALLENGE = gql`
query MyQuery($challenge_id: String) {
  team(where: {challenge_id: {_eq: $challenge_id}}) {
    id
    name
    data
    is_closed
    team_profiles {
      profile {
        display_name
        email
        photo_url
        id
      }
    }
    created_at
    submissions {
      data
      created_at
      id
    }
  }
}`;

export const GET_TEAMS_BY_IDS = gql`
query MyQuery($ids: [Int!]) {
  team(where: {id: {_in: $ids}}) {
    id
    name
    data
    is_closed
    team_profiles {
      profile {
        display_name
        email
        photo_url
        id
      }
    }
    created_at
    submissions {
      data
      created_at
      id
    }
  }
}`;

export const GET_TEAM_IDS = gql`
query MyQuery($ids: [Int!]) {
  team(where: {id: {_in: $ids}}) {
    id
  }
}`;


export const GET_TEAM_BY_MIGRATION_ID = gql`
query MyQuery($migration_id: String = "") {
  team(where: {migration_id: {_eq: $migration_id}}) {
    challenge_id
    created_at
    data
    id
    migration_id
    name
  }
}
`

export const GET_PARTICIPATING_TEAM = gql`
query MyQuery($challengeId: String) {
  team(where: {challenge_id: {_eq: $challengeId}}) {
    id
    name
    data
    team_profiles {
      profile {
        display_name
        photo_url
        id
      }
    }
  }
}`;


export const CLOSE_TEAM = gql`
mutation MyMutation($id: Int) {
  update_team(where: {id: {_eq: $id}}, _set: {is_closed: true}) {
    returning {
      id
      is_closed
    }
  }
}`;

export const OPEN_TEAM = gql`
mutation MyMutation($id: Int) {
  update_team(where: {id: {_eq: $id}}, _set: {is_closed: false}) {
    returning {
      id
      is_closed
    }
  }
}`;

// TODO use or remove
export const TEAM_ADMIN_STATS = gql`
query MyQuery {
  team {
    challenge_id
    submissions_aggregate(distinct_on: id) {
      aggregate {
        count
      }
    }
    requests_aggregate(distinct_on: id) {
      aggregate {
        count
      }
    }
  }
}
`;

export const GET_PROFILES_BY_TEAMID = gql`
query GET_PROFILES_BY_TEAMID($teamID: Int) {
  team(where: {id: {_eq: $teamID}}) {
  team_profiles {
    profile {
      email
      id
    }
  }
  }
}`

export const DELETE_TEAM = gql`
mutation MyMutation($team_id: Int!) {
  delete_team_by_pk(id: $team_id) {
    id
  }
}
`
