import { gql } from "@apollo/client/core";

export const GET_TAGS = gql`
query TagQuery {
  tag(order_by: {name: asc}) {
    hex_code
    id
    name
    tag_participations_aggregate {
      aggregate {
        count
      }
    }
  }
}
`;


export const INSERT_TAG = gql`
mutation TagMutation($hex_code: String = "", $name: String = "") {
  insert_tag_one(object: {hex_code: $hex_code, name: $name}) {
    hex_code
    id
    name
  }
}
`;


export const DELETE_TAG = gql`
mutation TagMutation($id: Int!) {
  delete_tag_by_pk(id: $id) {
    id
  }
}
`;


export const INSERT_TAG_PARTICIPATION_RELATIONS = gql`
mutation TagParticipationMutation($objects: [tag_participation_insert_input!]!) {
  insert_tag_participation(objects: $objects, on_conflict: {constraint: tag_participation_pkey}) {
    affected_rows
  }
}
`


export const REMOVE_TAG_FROM_PARTICIPATION = gql`
mutation DeleteParticipation($participation_id: Int!, $tag_id: Int!) {
  delete_tag_participation_by_pk(participation_id: $participation_id, tag_id: $tag_id) {
    participation_id
  }
}
`

export const UPDATE_TAG = gql`
mutation UpdateTag($id: Int!, $hex_code: String = "", $name: String = "") {
  update_tag_by_pk(pk_columns: {id: $id}, _set: {name: $name, hex_code: $hex_code}) {
    name
  }
}
`;
