import { gql } from "@apollo/client/core";

export const INSERT_REQUEST_WITH_MESSAGE = gql`
mutation MyMutation($profile_id: Int, $team_id: Int, $message: String) {
  insert_request_one(object: {profile_id: $profile_id, team_id: $team_id, messages: {data: {content: $message, sender_id: $profile_id}}, status: "pending"}, on_conflict: {constraint: request_team_id_profile_id_key, update_columns: status}) {
    id
  }
}
`;

export const INSERT_REQUEST = gql`
mutation MyMutation($profile_id: Int, $team_id: Int) {
  insert_request_one(object: {profile_id: $profile_id, team_id: $team_id, status: "pending"}, on_conflict: {constraint: request_team_id_profile_id_key, update_columns: [status]}) {
    id
  }
}

`

export const GET_REQUEST_BY_ID = gql`
query MyQuery($id: Int!) {
  request_by_pk(id: $id) {
    id
    team_id
    profile_id
    profile {
      id
      email
    }
    status
    team {
      name
      challenge_id
      team_profiles {
        profile {
          id
          email
        }
      }
    }
  }
}
`


export const GET_REQUESTS = gql`
subscription MyQuery($team_id: Int) {
  request(where: {team_id: {_eq: $team_id}}) {
    id
    profile_id
    status
    team_id
    profile {
      id
      display_name
      photo_url
    }
		messages {
      content
      sender_id
      created_at
    }
  }
}
`;

export const GET_REQUESTS_BY_CHALLENGE = gql`
query MyQuery($challenge_id: String) {
  team(where: {challenge_id: {_eq: $challenge_id}}) {
    requests {
      id
      profile_id
      status
      team_id
      team {
        name
      }
      created_at
      profile {
        id
        display_name
        email
      }
    }
  }
}
`;

export const GET_REQUESTS_BY_PROFILE = gql`
subscription MyQuery($profile_id: Int) {
  request(where: {profile_id: {_eq: $profile_id}}) {
    id
    status
    profile_id
    messages(order_by: {created_at: asc}) {
      content
      sender_id
      created_at
    }
    profile_id
    profile {
      id
      display_name
      photo_url
    }
    team {
      name
      data
      id
      team_profiles {
        profile_id
        profile {
          id
          display_name
          photo_url
        }
      }
    }
  }
}

`

export const DELETE_REQUEST_BY_ID = gql`
mutation MyMutation($id: Int) {
  delete_request(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
`;


export const GET_REQUESTS_WITH_PROFILE_CHALLENGE = gql`
subscription MyQuery($profileId: Int, $challengeId: String) {
  request(where: {profile_id: {_eq: $profileId}, team: {challenge_id: {_eq: $challengeId}}, status: {_eq: "pending"}}) {
    id
    profile_id
    status
    team_id
    team {
      name
    }
  }
}
`;

export const UPDATE_REQUEST = gql`
mutation UpdateRequest($id: Int!, $status: String = "pending") {
  update_request_by_pk(pk_columns: {id: $id}, _set: {status: $status}) {
    id
    team_id
    profile_id
    profile {
      id
      email
    }
    status
    team {
      name
      challenge_id
      team_profiles {
        profile {
          id
          email
        }
      }
    }
  }
}
`;

export const CANCEL_REMAINING_REQUESTS = gql`
mutation CancellRemainingRequests($profile_id: Int, $challenge_id: String = "") {
  update_request(where: {status: {_eq: "pending"}, profile_id: {_eq: $profile_id}, team: {challenge_id: {_eq: $challenge_id}}}, _set: {status: "cancelled"}) {
    affected_rows
  }
}
`

export const ADD_REQUEST_MESSAGE = gql`
mutation MyMutation($id: Int, $message: jsonb) {
  update_request(where: {id: {_eq: $id}}, _append: {messages: $message}) {
    returning {
      id
    }
  }
}
`;


export const INSERT_TEAM_REQUEST = gql`
mutation MyMutation($profile_id: Int, $team_id: Int, $status: String = "") {
  insert_request_one(object: {profile_id: $profile_id, team_id: $team_id, status: $status}) {
    id
  }
}
`;


export const UPDATE_TEAM_REQUEST = gql`
mutation MyMutation($profile_id: Int!, $team_id: Int!, $status: String) {
  update_request_by_pk(pk_columns: {profile_id: $profile_id, team_id: $team_id}, _append: {}, _set: {status: $status}) {
    id
    status
    profile_id
  }
}
`;
