import { ParticipationMinFields } from '@ekipa/shared';
import { ParticipationStatus, ParticipationType } from '../models/participation.model';



export const getParticipationStatus = (participation: ParticipationMinFields): ParticipationStatus => {
  if (participation.participation_type === ParticipationType.Solo) {
    return ParticipationStatus.Solo
  } else if (participation.participation_type === ParticipationType.Team) {
    if (participation.team_id) {
      return ParticipationStatus.HasTeam
    } else {
      return ParticipationStatus.LookingForTeam
    }
  }
}

