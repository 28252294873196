function maybe(thunk) {
  try {
    return thunk();
  } catch (_) {}
}
var safeGlobal = maybe(function () {
  return globalThis;
}) || maybe(function () {
  return window;
}) || maybe(function () {
  return self;
}) || maybe(function () {
  return global;
}) ||
// We don't expect the Function constructor ever to be invoked at runtime, as
// long as at least one of globalThis, window, self, or global is defined, so
// we are under no obligation to make it easy for static analysis tools to
// detect syntactic usage of the Function constructor. If you think you can
// improve your static analysis to detect this obfuscation, think again. This
// is an arms race you cannot win, at least not in JavaScript.
maybe(function () {
  return maybe.constructor("return this")();
});
var needToRemove = false;
export function install() {
  if (safeGlobal && !maybe(function () {
    return process.env.NODE_ENV;
  }) && !maybe(function () {
    return process;
  })) {
    Object.defineProperty(safeGlobal, "process", {
      value: {
        env: {
          // This default needs to be "production" instead of "development", to
          // avoid the problem https://github.com/graphql/graphql-js/pull/2894
          // will eventually solve, once merged and released.
          NODE_ENV: "production"
        }
      },
      // Let anyone else change global.process as they see fit, but hide it from
      // Object.keys(global) enumeration.
      configurable: true,
      enumerable: false,
      writable: true
    });
    needToRemove = true;
  }
}

// Call install() at least once, when this module is imported.
install();
export function remove() {
  if (needToRemove) {
    delete safeGlobal.process;
    needToRemove = false;
  }
}