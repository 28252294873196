

import { gql } from "@apollo/client/core";


export const UPSERT_TEAM_PARTICIPANTS = gql`
mutation UpsertTeamParticipants($team_id: Int, $data: jsonb = "") {
  insert_team_participants_one(object: {data: $data, team_id: $team_id}, on_conflict: {constraint: team_participants_team_id_key, update_columns: data}) {
    data
    team_id
    updated_at
  }
}
`

export const SUBSCRIBE_TEAM_PARTICIPANTS = gql`
subscription TeamParticipantsSubscription($team_id: Int) {
  team_participants(where: {team_id: {_eq: $team_id}}) {
    data
  }
}

`
