import { gql } from "@apollo/client/core";



export const GET_FAQS = gql`
query PlatformFaqQuery {
	allPlatform_faqs{
    edges {
      node {
        body {
          __typename
          ... on Platform_faqBodyFaq_section {
            label
            primary {
              eyebrow_headline
              title
              description
              optional_image
            }
            fields {
              title
              text
            }
          }
        }
      }
    }
  }
}
`;
