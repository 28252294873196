import { gql } from "@apollo/client/core";

export const ADD_TEAM_MESSAGE = gql`
mutation MyMutation($id: Int, $message: jsonb) {
  update_team(where: {id: {_eq: $id}}, _append: {messages: $message}) {
    returning {
      id
      messages
    }
  }
}
`;
