import { gql } from "@apollo/client/core";


export const MESSAGE_CONTENT = gql`
fragment messageContent on message {
  content
  created_at
  id
  sender_id
  team_id
  updated_at
}

`



export const GET_MESSAGES_BY_TEAM = gql`
${MESSAGE_CONTENT}
subscription MessagesSubscription($team_id: Int) {
  message(where: {team_id: {_eq: $team_id}}, , order_by: {created_at: asc}) {
    ...messageContent
  }
}
`


export const INSERT_MESSAGE = gql`
mutation MyMutation($content: String = "", $team_id: Int, $sender_id: Int, $request_id: Int) {
  insert_message_one(object: {content: $content, team_id: $team_id, sender_id: $sender_id, request_id: $request_id}) {
    id
  }
}
`

