import { gql } from "@apollo/client/core";


export const GET_APPROVAL_BY_PARTICIPATION_ID = gql`
subscription MyQuery($participation_id: Int!) {
  participant_approval(where: {participation_id: {_eq: $participation_id}}) {
    created_at
    updated_at
    participation {
      challenge_id
      profile_id
      participation_type
      team {
        id
      }
    }
    id
  }
}
`

export const GET_APPROVALS_BY_TEAM_ID = gql`
subscription MyQuery($team_id: Int) {
  participant_approval(where: {participation: {team_id: {_eq: $team_id}}}) {
    created_at
    updated_at
    participation {
      challenge_id
      profile_id
      participation_type
      team {
        id
      }
    }
    id
  }
}
`

export const INSERT_APPROVAL_BY_PARTICIPATION_ID = gql`
mutation MyMutation($participation_id: Int!) {
  insert_participant_approval_one(object: {participation_id: $participation_id}) {
    id
  }
}
`

export const DELETE_APPROVAL_BY_PARTICIPATION_ID = gql`
mutation MyMutation($participation_id: Int!) {
  delete_participant_approval(where: {participation_id: {_eq: $participation_id}}) {
    affected_rows
  }
}
`
