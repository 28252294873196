import { gql } from "@apollo/client/core";

export const INSERT_INVITATION = gql`
mutation MyMutation($teamId: Int, $token: String, $email: String) {
  insert_invitation_one(object: {email: $email, team_id: $teamId, token: $token}) {
    id
  }
}
`;

export const GET_INVITATION = gql`
query MyQuery($token: String) {
  invitation(where: {email: {_eq: $token}}) {
    email
    id
    team {
      name
      challenge_id
    }
    team_id
  }
}
`;

export const GET_INVITATION_BY_EMAIL = gql`
query MyQuery($email: String) {
  invitation(where: {email: {_eq: $email}}) {
    email
    id
    team {
      name
      challenge_id
    }
    team_id
  }
}
`;

export const DELETE_INVITATION = gql`
mutation MyMutation($id: Int!) {
  delete_invitation_by_pk(id: $id) {
    id
  }
}
`;
