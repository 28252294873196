import { gql } from "@apollo/client/core";

export const DELETE_ALL = gql`
mutation DeleteAll {
  delete_submission(where: {}) {
    affected_rows
  }
  delete_request(where: {}) {
    affected_rows
  }
  delete_participation(where: {}) {
    affected_rows
  }
  delete_file(where: {}) {
    affected_rows
  }
  delete_profile(where: {}) {
    affected_rows
  }
  delete_invitation(where: {}) {
    affected_rows
  }
  delete_notification(where: {}) {
    affected_rows
  }
  delete_team(where: {}) {
    affected_rows
  }
  delete_message(where: {}) {
    affected_rows
  }
  delete_profile_role(where: {}) {
    affected_rows
  }
}
`
