import { gql } from "@apollo/client/core";
import { challengeCardFragment } from "./challenges.query";


export const GET_ONGOING_CHALLENGES = gql`
${challengeCardFragment}
{
  allOngoing_challenges_pages{
  	edges{
      node{
        intro
        body{
          __typename
          ... on Ongoing_challenges_pageBodyChallenge {
            primary {
              challenges_title
              challenges_subtitle
              text_alignment
              horizontal_padding
            }
            fields {
              challenge {
								... on Challenge {
                  ... challengeCardFragment
                }
              }
            }
          }
        }
      }
    }
  }
}
`
