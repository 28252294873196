import { __extends } from "tslib";
import { Observable } from "./Observable.js";
import { iterateObserversSafely } from "./iteration.js";
import { fixObservableSubclass } from "./subclassing.js";
function isPromiseLike(value) {
  return value && typeof value.then === "function";
}
var Concast = function (_super) {
  __extends(Concast, _super);
  function Concast(sources) {
    var _this = _super.call(this, function (observer) {
      _this.addObserver(observer);
      return function () {
        return _this.removeObserver(observer);
      };
    }) || this;
    _this.observers = new Set();
    _this.addCount = 0;
    _this.promise = new Promise(function (resolve, reject) {
      _this.resolve = resolve;
      _this.reject = reject;
    });
    _this.handlers = {
      next: function (result) {
        if (_this.sub !== null) {
          _this.latest = ["next", result];
          iterateObserversSafely(_this.observers, "next", result);
        }
      },
      error: function (error) {
        var sub = _this.sub;
        if (sub !== null) {
          if (sub) setTimeout(function () {
            return sub.unsubscribe();
          });
          _this.sub = null;
          _this.latest = ["error", error];
          _this.reject(error);
          iterateObserversSafely(_this.observers, "error", error);
        }
      },
      complete: function () {
        var sub = _this.sub;
        if (sub !== null) {
          var value = _this.sources.shift();
          if (!value) {
            if (sub) setTimeout(function () {
              return sub.unsubscribe();
            });
            _this.sub = null;
            if (_this.latest && _this.latest[0] === "next") {
              _this.resolve(_this.latest[1]);
            } else {
              _this.resolve();
            }
            iterateObserversSafely(_this.observers, "complete");
          } else if (isPromiseLike(value)) {
            value.then(function (obs) {
              return _this.sub = obs.subscribe(_this.handlers);
            });
          } else {
            _this.sub = value.subscribe(_this.handlers);
          }
        }
      }
    };
    _this.cancel = function (reason) {
      _this.reject(reason);
      _this.sources = [];
      _this.handlers.complete();
    };
    _this.promise.catch(function (_) {});
    if (typeof sources === "function") {
      sources = [new Observable(sources)];
    }
    if (isPromiseLike(sources)) {
      sources.then(function (iterable) {
        return _this.start(iterable);
      }, _this.handlers.error);
    } else {
      _this.start(sources);
    }
    return _this;
  }
  Concast.prototype.start = function (sources) {
    if (this.sub !== void 0) return;
    this.sources = Array.from(sources);
    this.handlers.complete();
  };
  Concast.prototype.deliverLastMessage = function (observer) {
    if (this.latest) {
      var nextOrError = this.latest[0];
      var method = observer[nextOrError];
      if (method) {
        method.call(observer, this.latest[1]);
      }
      if (this.sub === null && nextOrError === "next" && observer.complete) {
        observer.complete();
      }
    }
  };
  Concast.prototype.addObserver = function (observer) {
    if (!this.observers.has(observer)) {
      this.deliverLastMessage(observer);
      this.observers.add(observer);
      ++this.addCount;
    }
  };
  Concast.prototype.removeObserver = function (observer, quietly) {
    if (this.observers.delete(observer) && --this.addCount < 1 && !quietly) {
      this.handlers.complete();
    }
  };
  Concast.prototype.cleanup = function (callback) {
    var _this = this;
    var called = false;
    var once = function () {
      if (!called) {
        called = true;
        _this.observers.delete(observer);
        callback();
      }
    };
    var observer = {
      next: once,
      error: once,
      complete: once
    };
    var count = this.addCount;
    this.addObserver(observer);
    this.addCount = count;
  };
  return Concast;
}(Observable);
export { Concast };
fixObservableSubclass(Concast);
