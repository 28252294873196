export const environment = {
  production: false,
  baseUrl: 'https://next.ekipa.de',
  prismicGraphQL: 'https://ekipa.prismic.io/graphql',
  coreGraphQL: 'wss://staging.ekipa.de/hasura/v1/graphql',
  apiURL: 'https://staging.ekipa.de/rest/',
  firebase: {
    apiKey: 'AIzaSyBlvl1450nhgvJfUqegXbLuE95oD7FWWZ8',
    authDomain: 'test-d324a.firebaseapp.com',
    databaseURL: 'https://test-d324a.firebaseio.com',
    projectId: 'test-d324a',
    storageBucket: 'test-d324a.appspot.com',
    messagingSenderId: '76998731681'
  }
};
