export enum ParticipationOption {
  Solo = 'solo',
  JoinTeam = 'join',
  CreateTeam = 'team',
  LeaveChallenge = 'leave'
}

export type ParticipationStyleByChallenge = { [challengeId: string]: string };

export enum ParticipationType {
  Solo = 'solo',
  Team = 'team'
}

export enum ParticipationAction {
  JoinAlone = 'alone',
  CreateTeam = 'create',
  JoinTeam = 'join'
}


/**
 * ParticipationStatus can be deduced from participation_type and team_id.
 * LookingForTeam is participation_type = 'team' without a team_id
 */
export enum ParticipationStatus {
  Solo = 'solo',
  LookingForTeam = 'lookingForTeam',
  HasTeam = 'team'
}