export const allFaculties = [
  'Law',
  'Economics',
  'Social sciences',
  'Educational sciences',
  'Psychology',
  'Sports Science',
  'Theology',
  'Philosophy',
  'History',
  'Linguistics and Cultural Studies',
  'Modern Philologies',
  'Earth Sciences / Geography',
  'Computer Science',
  'Mathematics',
  'Physics',
  'Communication',
  'Media',
  'Art and Music',
  'Design',
  'Nutritional Sciences',
  'Engineering',
  'Agriculture and forestry',
  'Medicine and Public Health'
];
