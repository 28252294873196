import { __assign, __rest } from "tslib";
import "../../utilities/globals/index.js";
import { visit } from 'graphql';
import { ApolloLink } from "../core/index.js";
import { Observable } from "../../utilities/index.js";
import { serializeFetchParameter } from "./serializeFetchParameter.js";
import { selectURI } from "./selectURI.js";
import { parseAndCheckHttpResponse } from "./parseAndCheckHttpResponse.js";
import { checkFetcher } from "./checkFetcher.js";
import { selectHttpOptionsAndBodyInternal, defaultPrinter, fallbackHttpConfig } from "./selectHttpOptionsAndBody.js";
import { createSignalIfSupported } from "./createSignalIfSupported.js";
import { rewriteURIForGET } from "./rewriteURIForGET.js";
import { fromError } from "../utils/index.js";
import { maybe } from "../../utilities/index.js";
var backupFetch = maybe(function () {
  return fetch;
});
export var createHttpLink = function (linkOptions) {
  if (linkOptions === void 0) {
    linkOptions = {};
  }
  var _a = linkOptions.uri,
    uri = _a === void 0 ? '/graphql' : _a,
    preferredFetch = linkOptions.fetch,
    _b = linkOptions.print,
    print = _b === void 0 ? defaultPrinter : _b,
    includeExtensions = linkOptions.includeExtensions,
    useGETForQueries = linkOptions.useGETForQueries,
    _c = linkOptions.includeUnusedVariables,
    includeUnusedVariables = _c === void 0 ? false : _c,
    requestOptions = __rest(linkOptions, ["uri", "fetch", "print", "includeExtensions", "useGETForQueries", "includeUnusedVariables"]);
  if (__DEV__) {
    checkFetcher(preferredFetch || backupFetch);
  }
  var linkConfig = {
    http: {
      includeExtensions: includeExtensions
    },
    options: requestOptions.fetchOptions,
    credentials: requestOptions.credentials,
    headers: requestOptions.headers
  };
  return new ApolloLink(function (operation) {
    var chosenURI = selectURI(operation, uri);
    var context = operation.getContext();
    var clientAwarenessHeaders = {};
    if (context.clientAwareness) {
      var _a = context.clientAwareness,
        name_1 = _a.name,
        version = _a.version;
      if (name_1) {
        clientAwarenessHeaders['apollographql-client-name'] = name_1;
      }
      if (version) {
        clientAwarenessHeaders['apollographql-client-version'] = version;
      }
    }
    var contextHeaders = __assign(__assign({}, clientAwarenessHeaders), context.headers);
    var contextConfig = {
      http: context.http,
      options: context.fetchOptions,
      credentials: context.credentials,
      headers: contextHeaders
    };
    var _b = selectHttpOptionsAndBodyInternal(operation, print, fallbackHttpConfig, linkConfig, contextConfig),
      options = _b.options,
      body = _b.body;
    if (body.variables && !includeUnusedVariables) {
      var unusedNames_1 = new Set(Object.keys(body.variables));
      visit(operation.query, {
        Variable: function (node, _key, parent) {
          if (parent && parent.kind !== 'VariableDefinition') {
            unusedNames_1.delete(node.name.value);
          }
        }
      });
      if (unusedNames_1.size) {
        body.variables = __assign({}, body.variables);
        unusedNames_1.forEach(function (name) {
          delete body.variables[name];
        });
      }
    }
    var controller;
    if (!options.signal) {
      var _c = createSignalIfSupported(),
        _controller = _c.controller,
        signal = _c.signal;
      controller = _controller;
      if (controller) options.signal = signal;
    }
    var definitionIsMutation = function (d) {
      return d.kind === 'OperationDefinition' && d.operation === 'mutation';
    };
    if (useGETForQueries && !operation.query.definitions.some(definitionIsMutation)) {
      options.method = 'GET';
    }
    if (options.method === 'GET') {
      var _d = rewriteURIForGET(chosenURI, body),
        newURI = _d.newURI,
        parseError = _d.parseError;
      if (parseError) {
        return fromError(parseError);
      }
      chosenURI = newURI;
    } else {
      try {
        options.body = serializeFetchParameter(body, 'Payload');
      } catch (parseError) {
        return fromError(parseError);
      }
    }
    return new Observable(function (observer) {
      var currentFetch = preferredFetch || maybe(function () {
        return fetch;
      }) || backupFetch;
      currentFetch(chosenURI, options).then(function (response) {
        operation.setContext({
          response: response
        });
        return response;
      }).then(parseAndCheckHttpResponse(operation)).then(function (result) {
        observer.next(result);
        observer.complete();
        return result;
      }).catch(function (err) {
        if (err.name === 'AbortError') return;
        if (err.result && err.result.errors && err.result.data) {
          observer.next(err.result);
        }
        observer.error(err);
      });
      return function () {
        if (controller) controller.abort();
      };
    });
  });
};
