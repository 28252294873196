import { gql } from "@apollo/client/core";



export const GET_PROFILES_BY_EMAIL_LIKE = gql`
query GetProfilesLike($_ilike: bpchar = "") {
  profile(limit: 15, where: {email: {_ilike: $_ilike}}, order_by: {created_at: desc}) {
    created_at
    data
    display_name
    email
    id
    photo_url
    type
    updated_at
    requests {
      id
      team {
        challenge_id
      }
      status
    }
    participations {
      participation_type
      challenge_id
      team_id
      team {
        id
        name
      }
    }
    is_complete
  }
}
`;

export const GET_PROFILE_BY_EMAIL = gql`
query MyQuery($_eq: bpchar = "") {
  profile(where: {email: {_ilike: $_eq}}) {
      created_at
      data
      display_name
      email
      id
      photo_url
      type
      updated_at
      requests {
        id
        team {
          challenge_id
        }
        status
      }
      participations {
        participation_type
        challenge_id
        team_id
        team {
          id
          name
        }
      }
      is_complete
    }
  }
`;


export const GET_PROFILE_ID_BY_EMAIL = gql`
query MyQuery($email: bpchar) {
    profile(where: {email: {_eq: $email}}) {
      id
    }
}
`

export const GET_PROFILE_BY_ID = gql`
query GET_PROFILE_BY_ID($id: Int) {
    profile(where: {id: {_eq: $id}}) {
      user_uid
      created_at
      data
      display_name
      email
      id
      photo_url
      type
      updated_at
      requests {
        id
        team {
          challenge_id
        }
        status
      }
      participations {
        created_at
        challenge_id
        team_id
        participation_type
        team {
          id
          name
        }
      }
    }
  }
`;

export const GET_PROFILE_BY_USER_UID = gql`
query GET_PROFILE_BY_USER_UID($uid: bpchar) {
  profile(where: {user_uid: {_eq: $uid}}) {
    id
    email
    requests {
      id
    }
    participations {
      id
      challenge_id
      team_id
    }
  }
}
`

export const GET_ALL_PROFILES = gql`
query MyQuery {
  profile {
    created_at
    data
    display_name
    email
    id
    photo_url
    type
    updated_at
    participations_aggregate {
      aggregate {
        count
      }
    }
  }
}
`;

export const UPSERT_PROFILE = gql`
mutation UpsertProfile(
  $email: bpchar = "",
  $data: jsonb = "",
  $display_name: bpchar = "",
  $photo_url: bpchar ="",
  $type: bpchar = "",
  $user_uid: bpchar = ""
  ) {
  insert_profile_one(object: {
    email: $email,
    data: $data,
    display_name: $display_name,
    photo_url: $photo_url,
    type: $type,
    user_uid: $user_uid
  }, on_conflict: {constraint: profile_email_key, update_columns: [data, display_name, photo_url, type, user_uid, email]}) {
    data
    display_name
    email
    photo_url
    id
    type
    updated_at
    user_uid
  }
}
`;

export const UPDATE_PROFILE_BY_ID = gql`
mutation UpdateProfile($id: Int!, $data: jsonb = "", $display_name: bpchar = "", $photo_url: bpchar = "", $type: bpchar = "") {
  update_profile_by_pk(pk_columns: {id: $id}, _set: {data: $data, display_name: $display_name, photo_url: $photo_url, type: $type}) {
    data
    display_name
    email
    photo_url
    id
    type
    updated_at
    user_uid
  }
}
`

export const UPDATE_EMAIL_BY_ID = gql`
mutation MyMutation($user_uid: bpchar = "", $email: bpchar = "") {
  update_profile(where: {user_uid: {_eq: $user_uid}}, _set: {email: $email})
}
`;

export const DELETE_PROFILE_BY_ID = gql`
mutation DELETE_PROFILE_BY_ID ($id: Int!) {
  delete_profile (
    where: {
      id: {
        _eq: $id
      }
    }
  ) {
    affected_rows
    returning {
      display_name
      email
      participations {
        team_id
      }
    }
  }
}
`;
