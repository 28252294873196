import { gql } from "@apollo/client/core";


export const QUERY_ROLES = gql`
query MyQuery {
  profile_role {
    created_at
    email
    role
    id
  }
}
`

export const GET_ROLE_BY_EMAIL = gql`
query MyQuery($email: bpchar = "") {
  profile_role(where: {email: {_eq: $email}}) {
    role
  }
}
`

export const UPSERT_ROLE_ENTRY = gql`
mutation MyMutation($email: bpchar = "", $role: String = "") {
  insert_profile_role_one(object: {email: $email, role: $role}, on_conflict: {constraint: profile_role_email_role_key, update_columns: updated_at}) {
    id
  }
}
`;


export const DELETE_ROLE_ENTRY = gql`
mutation MyMutation($id: Int = 10) {
  delete_profile_role_by_pk(id: $id) {
    id
  }
}
`
